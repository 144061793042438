import React from "react"
import { Link } from "gatsby"

import { Layout, Seo } from "../layout"

export default function AboutPage(props) {
  return (
    <Layout path={props.path}>
      <Seo title="About" />
      <main className="tg-main tg-news">
        <p className="p-4 sm:p-16 text-xl max-w-4xl mx-auto leading-9">
          Trailguide is a software company that specializes in creating
          innovative solutions for the tourism industry. We aim to help tourism
          businesses digitalize their outdoor offerings, making them easily
          accessible and convenient for guests and visitors. Our goal is to
          provide the best possible experience for tourists through the use of
          our software and technology.
        </p>

        <Contact
          cover="bjorn/bjorn-riding.jpg"
          portrait="bjorn/bjorn-portrait-2.jpg"
        >
          <h4 className="p-4 pb-0">BJØRN JARLE KVANDE</h4>
          <p className="px-4 pb-6 text-sm">
            Founder, CEO, and main developer. Thinks the best thing in the world
            is coding and gets overly excited when a little piece of code can be
            improved, simplified, or removed. Enjoys biking and skiing on his
            spare time. <Link to="/coding">My own little blog on the site</Link>
            .
          </p>
        </Contact>

        <Contact
          cover="peter/peter-biking2.jpg"
          portrait="peter/peter-portrait.jpg"
        >
          <h4 className="p-4 pb-0">PETER SEIDL</h4>
          <p className="px-4 pb-6">
            Co-founder, map designer, and developer of our digital map
            infrastructure. Loves playing around with GIS software, 3D rendering
            platforms and building open source solutions. Enjoys all sorts of
            biking and skiing in his spare time.
          </p>
        </Contact>

        <Contact
          cover="gunther/gunther-aran.jpg"
          portrait="gunther/gunther-riding.jpg"
        >
          <h4 className="p-4 pb-0">GÜNTHER</h4>
          <p className="px-4 pb-6">
            Responsible for transport, office space, accomodation and marketing.
            A good mix of German and Italian. Enjoys hanging out and relaxing at
            beautiful spots around Europe.
          </p>
        </Contact>
      </main>
    </Layout>
  )
}

function Contact(props = {}) {
  return (
    <section
      className="min-h-screen bg-center bg-no-repeat bg-cover p-8 sm:p-16"
      style={{
        backgroundImage: `url(https://res.cloudinary.com/trailguide-as/image/upload/c_limit,dpr_auto,w_auto:100:1600/v1/${props.cover})`,
      }}
    >
      <div className="w-72 max-w-full mx-auto sm:mx-12 bg-white text-gray-900 shadow-lg rounded border-4 border-white leading-6">
        <img
          alt={props.portrait}
          className="rounded rounded-b-none"
          src={`https://res.cloudinary.com/trailguide-as/image/upload/c_limit,w_400/v1/${props.portrait}`}
        />
        {props.children}
      </div>
    </section>
  )
}
